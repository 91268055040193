<div class="root" *cygovLoader="loading" [ngClass]="{'restrict-overflow':showRolePermission}">
  <ng-container *ngIf="!isBnb && !isBeecher && !isMidMarket ; else bnbNBeacherTemplate">
    <div class="user-setting-cont">
      <div class="entity-selection-cont">
        <div class="setting-account-title">
          <div cygovSvgIcon [svgName]="'settings-icon'" class="setting-icon"></div>
          <span class="text-title">centraleyes account settings</span>
        </div>
        <div *ngIf="!entityIdInUrl" class="entity-dropdown-cont">
          <cygov-select class="entity-dropdown" [items]="rootEntities" [clearable]="false"
            bindLabel="name" placeholder="Select Entity" [searchable]="true" [customArrow]="true"
            [ngModelValue]="entity?.name" [disabled]="innerLoading"
            (ngModelValueChange)="selectEntityForSettings($event)">
          </cygov-select>
        </div>
      </div>
      <div [ngClass]="{'first-section-tabs': true, 'userpermission-mode': !entitySelectionMode }">
        <ng-container
          *ngTemplateOutlet="tabTemplate; context: { tabOptions: upperTabsContentOptions, tabIdentifierLabel: 'upperTab', addScroll: 'true' }">
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>


<!-- for resend welcome email popup -->
<ng-template #WelcomeHeader>
  <div class="popup-header-icon" cygovSvgIcon [svgName]="'send-invitation'"></div>
</ng-template>
<ng-template #WelcomeContent>
  <div class="popup-content-body">
    <div class="line-1">Welcome email was sent successfully</div>
    <div *ngIf="selectedUsers && selectedUsers.length" class="line-2">to <span
        class="name">{{selectedUsers.length>1?selectedUsers.length:selectedUsers[0].user.name}}</span>
    </div>
  </div>
</ng-template>
<ng-template #WelcomeFooter>

</ng-template>
<!-- for resend enrollment -->
<ng-template #EnrollmentHeader>
  <div class="popup-header-icon" cygovSvgIcon [svgName]="'user'"></div>
</ng-template>
<ng-template #EnrollmentContent>
  <div *ngIf="selectedUsers && selectedUsers.length" class="popup-content-body">
    <div class="line-1">Please note that all login information</div>
    <div class="line-2">for <span class="name">{{selectedUsers.length>1?selectedUsers.length+'
        Users':selectedUsers[0].user.name}}</span> will be reset</div>
  </div>
</ng-template>
<ng-template #EnrollmentFooter>
  <div class="popup-content-footer">
    <cygov-button [buttonText]="'CONFIRM'" [buttonWidth]="'5vw'" [buttonColor]="'#29a9eb'"
      [hoverColor]="'#0F92E3'" (click)="enrollmentConfirm()">
    </cygov-button>
  </div>
</ng-template>
<!-- KMS Modal -->
<!-- ======================================== -->
<!-- Tabs template -->
<!-- ======================================== -->
<ng-template #tabTemplate let-tabOptions="tabOptions" let-identifier='tabIdentifierLabel'
  let-addScroll="addScroll">
  <ul ngbNav #nav="ngbNav" [activeId]="identifier + '#' + tabIdentifer[identifier]"
    (activeIdChange)="changeTabActiveId($event)" [ngClass]="['nav-tabs', identifier]">
    <ng-container *ngFor="let option of tabOptions; let indx=index">

      <li [ngbNavItem]="identifier + '#' + (indx+1)"
        [ngClass]="{'active-nav': tabIdentifer[identifier] == (indx + 1), 'disabled': innerLoading || option.disabled }"
        [disabled]="innerLoading || option.disabled" [domId]="identifier + '-'+ indx">
        <a ngbNavLink class="lato-16-n-vw text-capitalize">{{ option.label }}</a>
        <ng-template ngbNavContent>
          <ng-container *ngTemplateOutlet="option.content"></ng-container>
        </ng-template>
      </li>

    </ng-container>
  </ul>
  <div [ngbNavOutlet]="nav" [ngClass]="{'scrollable': addScroll} " class="mt-2"></div>
</ng-template>

<!-- ======================================== -->
<!-- Choose Entity Content -->
<!-- ======================================== -->
<ng-template #generalTab>
  <div *ngIf="entitySelectionMode && !singleEntityLenghtCheck" class="choose-entity-cont">
    <div class="choose-entity-title lato-18-n-vw">
      Choose Entity
    </div>
    <div class="entities-list">
      <div class="entities-box">
        <ng-container *ngFor="let entity of rootEntities;">
          <div class="entity-name-cont" (click)="selectEntityForSettings(entity)">
            <div class="entity-name-text" [attr.title]="entity.name">
              <img [src]="entity.logoUrl" alt="entity-logo" class="entity-logo"
                *ngIf="entity.logoUrl">
              <span class="text-capitalize" *ngIf="!entity.logoUrl">{{ entity.name }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<!-- ======================================== -->
<!-- Entity Selection & KMS & User Section -->
<!-- ======================================== -->
<ng-template #entityKMSUserPermissionTemp>
  <div [ngClass]="{'spinner': innerLoading }" *ngIf="innerLoading">&nbsp;</div>
  <ng-container *cygovLoader="innerLoading">
    <ng-container *ngIf="entitySelectionMode">
      <div *ngTemplateOutlet="generalTab;"></div>
    </ng-container>

    <ng-container *ngIf="!entitySelectionMode">
      <ng-container *ngIf="isManagerialRole">
        <ng-container *ngTemplateOutlet="KMSTemplate"></ng-container>
      </ng-container>

      <ng-container *ngTemplateOutlet="usersPermissionTemplate;"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<!-- ======================================== -->
<!-- 1st Party Tab Content -->
<!-- ======================================== -->
<ng-template #firstPartyTemp>
  <p> 1st party </p>
</ng-template>

<!-- ======================================== -->
<!-- 3rd Party Tab Content -->
<!-- ======================================== -->
<ng-template #thirdPartyTemp>
  <p> 3rd party </p>
</ng-template>

<!-- ======================================== -->
<!-- Users & Permissions Section -->
<!-- ======================================== -->
<ng-template #usersPermissionTemplate>
  <ng-container>
    <div class="show-permission">
      <div [ngClass]="{'kms-allowed': isManagerialRole}" class=" root-curtain-open with-kms"
        [ngStyle]="{'border-top':!isMidMarket && !isBeecher && !isBnb?'2px solid #161a2c':'none'}">
        <div class="users-heading">
          <div cygovSvgIcon [svgName]="'user-icon-2'" class="user-icon"></div>
          <div class="users-section-title lato-18-b-vw">
            Users
          </div>
        </div>
        <div class="role-permission-header"
          [ngClass]="{'relative-position': lowerTabsContentOptions[0].relativePos }">
          <ng-container
            *ngTemplateOutlet="tabTemplate; context: { tabOptions: lowerTabsContentOptions, tabIdentifierLabel: 'lowerTab'}">
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

</ng-template>

<!-- ======================================== -->
<!-- KMS section -->
<!-- ======================================== -->
<ng-template #KMSTemplate>
  <div class="kms-cont1">
    <div class="kms-title-heading">
      <div cygovSvgIcon [svgName]="'key'" class="key-icon"></div>
      <span class="kms-title-cont lato-18-b-vw">Client Side Key Management</span>
    </div>
    <div class="kms-statistics-cont">
      <div class="kms-statistics-text">
        The Centraleyes platform is end-to-end encrypted by default. Through this setting, admin
        users can manually rotate the key encryption</div>
      <div class="kms-statistics-quotas">
        <div class="active-inactive-btn">
          <span class="inactive-toggle"
            [ngClass]="{'active': !kmsToggle && !activationInProgress}">Inactive</span>
          <span class="active-toggle" [ngClass]="{'active': kmsToggle}"
            (click)="toggleActivation(true)" cygovUserPermission [hiddenMode]="2">Active</span>
        </div>
        <div class="kms-loader">
          <div *cygovLoader="activationInProgress">
          </div>
        </div>
        <div class="inner-statistics">
          <span *ngIf="entity?.KMS?.rotations?.lastRotation" class="last-rotation-cont">Last
            Rotation: <span class="last-rotation-value">
              {{entity.KMS.rotations.lastRotation | date }}</span> </span>
          <span class="annual-allocation-cont">Annual Allocation: <span
              class="annual-allocation-value">{{entity?.KMS?.rotations?.count ?
              entity?.KMS?.rotations?.count : '0'}}/3</span></span>
        </div>
      </div>
      <div class="kms-statistics-rotate-cont">
        <button class="kms-statistics-rotate-btn"
          [ngClass]="{'active': !rotationInProgress && kmsToggle && entity?.KMS?.rotations?.count < 3}"
          (click)="runRotation()" cygovUserPermission [hiddenMode]="1">Rotate</button>
        <div class="kms-loader">
          <div *cygovLoader="rotationInProgress">
          </div>
        </div>
      </div>
      <div class="horiz-seprator"></div>
      <div class="risk-ai-toggle-container" *ngIf="isAITabAccess">
        <div class="heading-with-icon">
          <img class="robot-icon" src="assets/svg/robot-type2.svg" alt="Robot Icon">
          <div class="lato-18-n-vw title-text">Artificial Intelligence</div>
          <div class="lower-footer-toggle custom-toggle-bg"
            [class]="{'custom-margin-left': true, 'disable-toggle': isAIUpdateInProgress}">
            <cygov-toggle-button [id]="'0'" [isChecked]="aiData?.isActive"
              (changed)="aiData?.isActive ? deActivateRiskAI($event) : openRiskAiConfirmationModal($event) ">
            </cygov-toggle-button>
          </div>
          <div class="credits-container" *ngIf="aiData && aiData?.credits && aiData?.isActive">
            <div class="credits-div lato-16-n-vw">
              (<span>Credits &nbsp;</span> <span>{{aiData.usedCredits}}/</span>
              <span>{{aiData.credits}}</span>)
            </div>
          </div>
          <div class="logs-container" *ngIf="aiData && aiData.isActive && aiData.userName">
            <div class="lato-16-n-vw">{{ aiData.isActive ? 'Activated By:' : 'Deactivated By:' }}
            </div>
            <div class="lato-16-n-vw"> &nbsp; {{aiData.userName}} | </div>
            <div class="lato-16-n-vw">&nbsp; {{aiData.date | date: "MM.dd.yy"}}</div>
          </div>
        </div>
        <div class="sub-heading-with-deatail">
          <div class="title lato-16-b-vw">AI Risk Module</div>
          <div class="text lato-16-n-vw">The Centraleyes AI module automates risk creation and
            mapping, efficiently identifying and evaluating potential risks through advanced
            algorithms. Beyond simple recognition, it analyzes contextual information for a nuanced
            understanding of each risk and adapts dynamically.</div>
        </div>
        <div class="request-to-credit-button"
          *ngIf="aiData && aiData.credits && ( aiData.usedCredits >= aiData.credits)">
          <cygov-button class="save-btn" [buttonText]="'REQUEST CREDITS'" [buttonWidth]="'8vw'"
            [buttonHeight]="'3.22vh'" [buttonColor]="'#29A9EB'" [hoverColor]="'#0B76B9'"
            (click)="openCreditPackageModal(CreditPackageHeader, CreditPackageContent, CreditPackageFooter)">
          </cygov-button>
        </div>
      </div>
      <div class="horiz-seprator"></div>
      <div class="attack-surface-toggle-container" *ngIf="!isMidMarket && !isBeecher && !isBnb">
        <div class="heading-with-icon">
          <div class="lower-footer-toggle custom-toggle-bg" [class]="{'custom-margin-left': true}">
            <cygov-toggle-button [id]="'1'" [isChecked]="activeScan"
              (changed)="activateAttackSurface() ">
            </cygov-toggle-button>
          </div>
          <div class="lato-18-n-vw title-text">Attack Surface</div>
        </div>
        <div class="attack-surface-input-container">
          <div class="pencil-icon-wrapper"
            (click)="openAttackSurfaceModal(AttackSurfaceHeader, AttackSurfaceContent, AttackSurfaceFooter)"
            *ngIf="isEdit">
            <div class="pencil-icon" cygovSvgIcon [svgName]="'edit-button'"></div>
          </div>
          <input type="text" class="input-filed lato-18-n-vw" [(ngModel)]="domainValue"
            [disabled]="isEdit ||!activeScan">
        </div>
        <div class="request-to-credit-button activiate-button">
          <button (click)="scanAllDomains()" [disabled]="!activeScan ||!domainValue">SCAN</button>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- ======================================== -->
<!-- Users section -->
<!-- ======================================== -->
<ng-template #usersTemp>
  <div class="users-table-filters">
    <div class="curtain-header">
      <div class="header-left-menu">
        <ng-container *ngTemplateOutlet="filters"></ng-container>
        <div (click)="addNewUser()" class="lato-16-n-vw add-button add-user-btn"
          cygovUserPermission>
          <span class="add-icon">+</span>
          <span class="button-text">Add New User</span>
        </div>
      </div>

      <div class="header-right-menu">
        <div class="actions-container action-icon-tooltip" *ngIf="!isEditMode" cygovUserPermission>
          <div class="action-icon" [ngClass]="{
        'disabled': !isUserSelected()
      }" cygovSvgIcon (click)="deleteUserPopup()" ngbTooltip="Delete" container="body"
            [svgName]="'trash-icon'"></div>
          <!-- Commenting out till the feature is implemented -->
          <!-- <div class="action-icon" cygovSvgIcon (click)="onResetPhone()"
        ngbTooltip="Reset Phone" container="body" [svgName]="'phone'"></div> -->
          <div class="action-icon" [ngClass]="{'disabled': !isUserSelected()}" cygovSvgIcon
            (click)="resendEnrollmentPopup(EnrollmentHeader, EnrollmentContent, EnrollmentFooter)"
            ngbTooltip="Reset User" container="body" [svgName]="'user'"></div>
        </div>
        <div class="edit-mode-buttons" cygovUserPermission>
          <cygov-button *ngIf="isEditMode" class="save-btn" [buttonText]="'SAVE'"
            [buttonWidth]="'5.2vw'" [buttonHeight]="'3.22vh'" [buttonColor]="'#29A9EB'"
            [hoverColor]="'#0B76B9'" (click)="saveUpdatedUsers()">
          </cygov-button>
          <div class='edit-users-btn' [ngClass]="isEditMode ? 'cross-svg':'pencil-svg'" cygovSvgIcon
            [svgName]="isEditMode ? 'x-icon-blue':'edit-icon'" (click)="handleEditMode()"></div>
        </div>
      </div>
    </div>
    <cygov-user-list (onSort)="sortData($event)" (userSelected)="onUserSelected($event)"
      class="cygov-user-list" [rolesList]="allRoles" [childEntities]="childEntities"
      [userList]="filteredUserList" [vendorHashEntities]="vendorHash" [rootEntity]="entity">
    </cygov-user-list>
  </div>
</ng-template>

<!-- ======================================== -->
<!-- Role Permission Section -->
<!-- ======================================== -->
<ng-template #rolePermissionTemp>
  <div class="user-permission-section" *ngIf="isCurrentUserAccessRoleSection()">
    <div class="permission-content">
      <div class="role-box">
        <div class="new-role" (click)="displayRolePermission()" cygovUserPermission>
          <span class="box">
            New Role Group
          </span>
          <span class="new-icon">+</span>
        </div>
        <ng-container *ngFor="let role of defaultRoles; let i = index">
          <span class="capitalize default-role"
            [ngClass]="{'selected': selectedRole?.id === role.id}"
            (click)="selectRole($event, i, UsersSettingConstant.default)"
            *ngIf="!((role.name === 'mssp' || role.name === 'admin') && isCurrentUserAdmin)">
            {{ role.name }}
          </span>
        </ng-container>
        <span container="body" *ngFor="let role of customRoles; let i = index"
          [ngClass]="{'selected':selectedRole?.id===role.id}" class="capitalize custom-role"
          (mouseenter)="checkIfEllipsis($event.target)" [ngbTooltip]="role.name"
          [disableTooltip]="!showColumnTooltip" (click)="selectRole($event, i)">{{role.name}}
        </span>
      </div>
    </div>
    <div class="underline"></div>
  </div>
  <div class="role-permission-popup-cont"
    *ngIf="showRolePermission && isCurrentUserAccessRoleSection()">
    <cygov-role-permission (closeModal)="showRolePermission=false" [editMode]="roleSelected"
      [disableScreen]="isDefaultRole || currentUser?.isViewOnly" [entityId]="entityId"
      [entityList]="entityList" [selectedRole]="selectedRole" [vendorsList]="vendorsList"
      (onDelete)="deleteCustomRole($event)" [isGlobalUserSetting]="isGlobalUserSetting">
    </cygov-role-permission>
  </div>
</ng-template>

<!-- BnB / Beechers user setting -->
<ng-template #bnbNBeacherTemplate>
  <div class="curtain" #curtain></div>
  <div class="root-top" *ngIf="!showRolePermission">
    <div class="lato-18-n-vw uppercase">
      User Settings
    </div>
  </div>
  <ng-container *ngIf="!showRolePermission">
    <div class="show-permission">
      <div class="root-curtain-open">
        <div class="role-permission-header without-kms">
          <div class="users-permission-wrapper">
            <div class="users-tab"
              (click)="changePermissionHeaderToPermissions(UsersSettingScreens.users)">
              <div class="lato-18-b-vw capitalize align-text"
                [ngClass]="{'users-text': isUsersScreen}">
                {{ statusFilter }} Users
              </div>
            </div>
            <div class="permissions-tab"
              (click)="changePermissionHeaderToPermissions(UsersSettingScreens.permissions)">
              <div class="lato-18-b-vw capitalize align-text"
                [ngClass]="{'permissions-text': !isUsersScreen}">
                Role Permissions
              </div>
            </div>
          </div>
          <div class="search-and-add-new-wrapper" *ngIf="isUsersScreen">
            <!-- Commenting out code because search is required for all domains -->
            <!-- <div class="search-wrapper" *ngIf="!isBnb">
              <div class="search-icon" cygovSvgIcon [svgName]="'search-icon'"></div>
              <input type="text" class="form-control search" aria-label="Search"
                placeholder="Search" name="search" [(ngModel)]="searchText"
                (input)="filterChanged('search', searchText)" spellcheck="true" />
            </div> -->
            <div (click)="addNewUser()" class="lato-16-n-vw add-button" cygovUserPermission>
              <span class="add-icon">+</span>
              <span class="button-text">Add New User</span>
            </div>
          </div>
        </div>
        <div class="role-permission-header-underline"></div>
        <div class="curtain-header" *ngIf="isUsersScreen">
          <div class="header-left-menu">
            <ng-container *ngTemplateOutlet="filters"></ng-container>
          </div>

          <div class="header-right-menu">
            <div class="actions-container action-icon-tooltip" *ngIf="!isEditMode"
              cygovUserPermission>
              <div class="action-icon" [ngClass]="{
              'disabled': !isUserSelected()
            }" cygovSvgIcon (click)="deleteUserPopup()" ngbTooltip="Delete" container="body"
                [svgName]="'trash-icon'"></div>
              <!-- Commenting out till the feature is implemented -->
              <!-- <div class="action-icon" cygovSvgIcon (click)="onResetPhone()"
              ngbTooltip="Reset Phone" container="body" [svgName]="'phone'"></div> -->
              <div class="action-icon" [ngClass]="{'disabled': !isUserSelected()}" cygovSvgIcon
                (click)="resendEnrollmentPopup(EnrollmentHeader, EnrollmentContent, EnrollmentFooter)"
                ngbTooltip="Reset User" container="body" [svgName]="'user'"></div>
            </div>
            <div class="edit-mode-buttons" cygovUserPermission>
              <cygov-button *ngIf="isEditMode" class="save-btn" [buttonText]="'SAVE'"
                [buttonWidth]="'5.2vw'" [buttonHeight]="'3.22vh'" [buttonColor]="'#29A9EB'"
                [hoverColor]="'#0B76B9'" (click)="saveUpdatedUsers()">
              </cygov-button>
              <div class='edit-users-btn' [ngClass]="isEditMode ? 'cross-svg':'pencil-svg'"
                cygovSvgIcon [svgName]="isEditMode ? 'x-icon-blue':'edit-icon'"
                (click)="handleEditMode()"></div>
            </div>
          </div>
        </div>
        <cygov-user-list (onSort)="sortData($event)" *ngIf="isUsersScreen"
          (userSelected)="onUserSelected($event)" class="cygov-user-list" [rolesList]="allRoles"
          [childEntities]="childEntities" [userList]="filteredUserList"
          [vendorHashEntities]="vendorHash" [rootEntity]="entity">
        </cygov-user-list>

        <!-- Role Permission Section -->
        <!-- For Now disabling this feature so that the back-end gets completed -->
        <div class="user-permission-section"
          *ngIf="isCurrentUserAccessRoleSection() && !isUsersScreen">
          <div class="permission-content">
            <div class="role-box">
              <div class="new-role" (click)="displayRolePermission()" cygovUserPermission>
                <span class="box">
                  New Role Group
                </span>
                <span class="new-icon">+</span>
              </div>
              <span *ngFor="let role of defaultRoles; let i = index" class="capitalize default-role"
                [ngClass]="{'selected':selectedRole?.id===role.id}"
                (click)="selectRole($event, i ,UsersSettingConstant.default)">{{role.name}}</span>
              <span container="body" *ngFor="let role of customRoles; let i = index"
                [ngClass]="{'selected':selectedRole?.id===role.id}" class="capitalize custom-role"
                (mouseenter)="checkIfEllipsis($event.target)" [ngbTooltip]="role.name"
                [disableTooltip]="!showColumnTooltip" (click)="selectRole($event, i)">{{role.name}}
              </span>
            </div>
          </div>
          <div class="underline"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showRolePermission && isCurrentUserAccessRoleSection() && !isUsersScreen">
    <cygov-role-permission (closeModal)="showRolePermission=false" [editMode]="roleSelected"
      [disableScreen]="isDefaultRole || currentUser?.isViewOnly" [entityId]="entityId"
      [entityList]="entityList" [selectedRole]="selectedRole" [vendorsList]="vendorsList"
      (onDelete)="deleteCustomRole($event)">
    </cygov-role-permission>
  </ng-container>
</ng-template>

<ng-template #filters>
  <span class="lato-16-n-vw role-filter align-text">Filter by Role</span>
  <cygov-select class="with-margin-right filter-drop-down"
    [items]="roleFilterOptions | filterRemoveValue:{id:roleFilter.id}:'id'" [clearable]="false"
    [(ngModelValue)]="roleFilter" bindLabel="name"
    (ngModelValueChange)="filterChanged('role', roleFilter.id)" placeholder="User Role"
    [searchable]="false" [customArrow]="true">
  </cygov-select>
  <span class="lato-16-n-vw with-margin-left align-text">Filter by Status</span>
  <cygov-select class="filter-drop-down" [sortByAlphabet]="true"
    [items]="statusFilterOptions| filterRemoveValue:statusFilter" [clearable]="false"
    [(ngModelValue)]="statusFilter" [searchable]="false" [customArrow]="true"
    (ngModelValueChange)="filterChanged('status', statusFilter)" placeholder="Status">
  </cygov-select>
  <div class="search-wrapper">
    <div class="search-icon" cygovSvgIcon [svgName]="'search-icon'"></div>
    <input type="text" class="form-control search" aria-label="Search" placeholder="Search"
      name="search" [(ngModel)]="searchText" (keyup)="filterChanged('search', searchText)"
      spellcheck="true" />
  </div>
</ng-template>

<ng-template #CreditPackageHeader>
  <!-- Empty for filters -->
  <img class="robot-icon-cont" src="assets/svg/robots-type1.svg" alt="Robot Icon">
</ng-template>

<ng-template #CreditPackageContent>
  <div
    class='d-flex justify-content-center align-items-center flex-column confirm-filter-popup-content'>
    <span class='lato-20-n-vw'>Please note, that you do not have AI credits available.
    </span>
    <span class='lato-20-n-vw'>To add more please contact <span
        class="txt-blue">sales&#64;centraleyes.com</span>.
    </span>
  </div>
</ng-template>

<ng-template #CreditPackageFooter>
  <div class='confirm-filter-popup-footer d-flex justify-content-center'>
    <cygov-button class='standard-btn lato-14-n-vw' [buttonText]="'Ok'" [buttonWidth]="'5vw'"
      [textSize]="'14'" [buttonColor]="'#29A9EB'" [hoverColor]="'#0B76B9'" (click)="closeModal()">
    </cygov-button>
  </div>
</ng-template>


<ng-template #AttackSurfaceHeader>
  <!-- Empty for filters -->
  <span class="lato-24-n-vw text-white">Attack Surface</span>
</ng-template>

<ng-template #AttackSurfaceContent>
  <div
    class='d-flex justify-content-center align-items-center flex-column confirm-filter-popup-content'>
    <span class='lato-22-n-vw'>Please note, you are about to replace the current domain:
    </span>
    <span class='lato-22-n-vw'> <span
        class="txt-blue">{{domainValue?domainValue:'http://caliberbankflorida.com/'}}</span>
      &nbsp;
      <span class='lato-22-n-vw'>with:</span>
    </span>
    <div class="attack-surface-input-container">
      <input type="text" class="input-filed" [(ngModel)]="updatedDomainValue">
    </div>
  </div>
</ng-template>

<ng-template #AttackSurfaceFooter>
  <div class='atack-surface-footer d-flex justify-content-center'>
    <cygov-button class='standard-btn lato-14-n-vw' [buttonText]="'CONFIRM'" [buttonWidth]="'5vw'"
      [textSize]="'14'" [buttonColor]="'#29A9EB'" [hoverColor]="'#0B76B9'"
      (click)="domainEdit(true)">
    </cygov-button>
    <cygov-button class="button-margin" [buttonText]="'CANCEL'" [buttonColor]="'#030715'"
      [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5vw'" [textColor]="'#C5C9D1'"
      (click)="domainEdit(false)">
    </cygov-button>
  </div>
</ng-template>