import { FirstPartyTabs } from './../users-settings/users-settings.constant';
import { APIService, EntityTypeEnum, GetEntityQuery } from 'app/API.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UtilsService } from 'app/shared/utils.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RoleEnum, GetUserQuery, GetRoleQuery } from '../API.service';
import { GetEntityQueryExtended, EntityService } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { BoardService } from 'app/board/board.service';
import breadcrumbLinks from '../shared/breadcrumb-links';
import { getDomainMenu } from './constants';
import { ArrowsEnum, SideNav, Entity_Type } from 'app/shared/enums/shared.enum';
import { UserService } from 'app/shared/user.service';
import { AppLevelRoleEnum, AppLevelRoleEnumBnB } from 'app/shared/enums/appLevelRoles.enum';
import { ClientsScreenTitles, ClientsSubMenuPriority, ClientSubMenuEnum } from 'app/shared/enums/brownAndBrown.enum';
import { UsersSettingConstant } from 'app/users-settings/users-settings.constant';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import { UsersSettingsService } from 'app/users-settings/users-settings.service';
import { DefaultSettingsNewModalComponent } from 'app/third-party/default-settings-new-modal/default-settings-new-modal.component';
import { ThirdPartyService } from 'app/third-party/third-party.service';

@Component({
  selector: 'cygov-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  @ViewChild('sideNav') sideNav: ElementRef;
  user: GetUserQuery;
  showMenu = true;
  subEntities: GetEntityQuery[];
  menuItems: any[];
  entity: GetEntityQueryExtended = null;
  entityId: string;
  subEntityId: string;
  isAdminOrMssp = true;
  isEntityLeader = true;
  isSubEntityLeader = false;
  modalReference: NgbModalRef = null;
  defaultSettingSubMenuClass = ' ';
  subMenuClass = '';
  isVendorLeader = false;
  isParticipant = false;
  doesReportExist = false;
  isWizard = false;
  isWelcome = false;
  subscriptionList: Subscription[] = [];
  subEntityNameForBreakDown = '';
  permissions: any;
  board = 'board';
  boardNetskope = 'board-netskope';
  trainingCenter = 'training-center';
  defaultSettings = 'Default Settings';
  isCrbAdjustments = UtilsService.isCRB;
  isNetskope = UtilsService.isNetskope;
  isBnB = UtilsService.isBnB;
  isBnBCyberSite = UtilsService.isBnBCyberSite;
  isMidMarket = UtilsService.isMidMarket;
  isHowden = UtilsService.isHowden;
  disableUpArrow = true;
  disableDownArrow = true;
  isScroll = false;
  showBnbLogo = false;
  showMidMarketLogo: boolean = false;
  bnbLogoDir = '../../../assets/images/brown-brown-logo.png';
  bnbLogoAltName = 'Brown & Brown';
  layerPriority = ClientsSubMenuPriority;
  currentRole: GetRoleQuery;
  arrowsEnum = ArrowsEnum;
  sideNavEnum = SideNav;
  isDivLea: boolean;
  isRegLea: boolean;
  noArchivedFramework: boolean;
  archivedFramework: any;
  archivedRisk: any;
  currentRoutePath: string;
  isFromThirdPartyCollection: boolean;
  collectionOpened: boolean = false;
  isFirstTime: boolean = true;
  isDownClicked: boolean = false;
  vendorId: string = '';
  vendorMenuActive: boolean = false;
  showScrollArrows: boolean = true;
  isClient: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private entityService: EntityService,
    private logger: NGXLogger,
    private boardService: BoardService,
    private userService: UserService,
    private domainFrameworkService: DomainFrameworkService,
    private api: APIService,
    private usersSettingsService: UsersSettingsService,
    private thirdPartyService: ThirdPartyService
  ) {
    this.subscribeToRouteChange();
    if (window.location.href.includes('risk-map')) {
      this.showScrollArrows = false;
    }
  }

  isSideNavDisabled: boolean = false;
  async ngOnInit(): Promise<void> {
    this.currentRoutePath = this.router.url;
    try {
      this.closeModal();
      if (this.subscriptionList.length === 0) {
        this.subscriptionList.push(
          this.usersSettingsService.getIsAddNewUSerModalOpened().subscribe(res => {
            this.isSideNavDisabled = res;
          })
        );
      }
      // highlight the Vendors (Background and text) color when selected any child (collection/gaps)
      this.vendorMenuActive = this.activeRouteCaseVendorMenu();
      // await this.userService.getCurrentUser();
      this.user = !this.userService?.currentUser
        ? await this.userService.getCurrentUser()
        : this.userService?.currentUser;
      this.isClient = !!this.user?.onBoardingStatus;
      this.currentRole = this.userService.getCurrentRole();
      this.isWelcome = this.authService.isFirstTimeUser();
      this.isDivLea = this.userService.isBnbLeader(AppLevelRoleEnumBnB.DIVISION_LEADER);
      this.isRegLea = this.userService.isBnbLeader(AppLevelRoleEnumBnB.REGION_LEADER);
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      this.authService.welcomeGuideStatus.subscribe(async change => {
        if (change) {
          this.isWelcome = false;
          this.menuItems = await this.loadMenuItems(this.entityId, this.subEntityId);
        }
      });
      if (this.user) {
        this.permissions = JSON.parse(this.userService.getCurrentRole().screenPermissions);
      }
      this.isWizard = this.router.url.includes('wizard');
      this.isAdminOrMssp =
        this.user &&
        (this.userService.currentUserRole?.name.toLowerCase() === RoleEnum.ADMIN.toLowerCase() ||
          this.userService.currentUserRole?.name.toLowerCase() === RoleEnum.MSSP.toLowerCase() ||
          this.userService.currentUserRole?.name.toLowerCase() ===
            AppLevelRoleEnumBnB.USER_ADMINISTRATOR.toLowerCase());
      this.isVendorLeader =
        this.user && this.userService.currentUserRole?.name.toLowerCase() === RoleEnum.VENDOR.toLowerCase();
      this.isParticipant =
        this.user && this.userService.currentUserRole?.name.toLowerCase() === RoleEnum.PARTICIPANT.toLowerCase();

      this.entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId') || this.subEntityId;
      this.isFromThirdPartyCollection =
        this.router.url.includes('third-party') &&
        (this.router.url.includes('collection') ||
          this.router.url.includes('gaps') ||
          this.router.url.includes('vendorId'));
      // Adding this condition for a case when user navigates from remediation to collection in third party
      if (this.router.url.includes('third-party') && this.route.snapshot.queryParams?.vendorId) {
        this.thirdPartyService.vendorId = this.route.snapshot.queryParams.vendorId;
      }
      if (this.entityId) {
        if (this.router.url.includes('risk-register')) {
          this.subEntities = await this.entityService.listEntitiesByParentId(this.entityId);
        } else {
          this.subEntities = await this.entityService.listChildEntitysByParentId(this.entityId);
        }
        // this.entityService.subEntitiesListByParent[this.entityId] = this.subEntities;
      }
      const permissionEntityLeader = this.userService.hasPermissionHierarchy(AppLevelRoleEnum.ENTITY_LEADER, [
        this.entityId,
      ]);
      let permissionSubEntityLeader;
      if (this.subEntities) {
        permissionSubEntityLeader = this.userService.hasPermissionHierarchy(
          AppLevelRoleEnum.SUBENTITY_LEADER,
          this.subEntities.map(subEntity => subEntity?.id)
        );
      }
      this.isEntityLeader = this.entityId && permissionEntityLeader ? true : false;
      this.isSubEntityLeader = this.subEntities && permissionSubEntityLeader ? true : false;

      if (this.isParticipant) {
        const subEntity = await this.entityService.getEntity(this.user.entityId);
        this.entityId = this.entityId ? this.entityId : subEntity.parentId;
        this.subEntityId = this.subEntityId ? this.subEntityId : subEntity.id;

        this.menuItems = await this.loadMenuItemsForParticipant(this.entityId, this.subEntityId);
      } else {
        // await this.domainFrameworkService.getDomainSetting(); // api call to get domain specific settings
        // await this.domainFrameworkService.getDomainSetting(); // dummy function to set domain settings
        const domainSettings = await this.domainFrameworkService.getDomainSetting(); // domain setting from entity settings
        if (domainSettings && UtilsService.isNetskope) {
          // pass management tab permission in getDomainMenu
          const adminORmsspPermission = this.userService.hasPermissionHierarchy(AppLevelRoleEnum.MSSP);
          // new menu items to append
          const menuToAppend = getDomainMenu({
            key: domainSettings.key,
            board: this.boardNetskope,
            entityId: this.entityId,
            clientsPermission: adminORmsspPermission,
            screenPermissions: JSON.parse(this.currentRole.screenPermissions),
          });
          if (menuToAppend && menuToAppend.length) {
            this.menuItems = await this.formMenu(menuToAppend, true);
            console.log('this.menuItems: ', this.menuItems);
          }
        } else {
          this.menuItems = await this.formMenu();
        }
      }
      if (this.entityId && this.userService.hasPermissionHierarchy(AppLevelRoleEnum.ENTITY_LEADER, [this.entityId])) {
        this.entity = await this.entityService.setLogoUrl(await this.entityService.getEntity(this.entityId));
      } else {
        this.entity = null;
      }
      if (this.subscriptionList.length <= 1) {
        this.subscriptionList.push(
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          this.boardService.doesReportExists.subscribe(async status => {
            // checks if report existence status is changed
            if (this.doesReportExist !== status.valueOf()) {
              this.doesReportExist = status.valueOf();
              // checks if user is on the wizard page
              this.isWizard = this.router.url.includes('wizard');
              if (!this.isParticipant && this.entityId) {
                this.menuItems = await this.loadMenuItems(this.entityId, this.subEntityId);
              }
            }
          }),
          this.api.OnCreateEntityListener().subscribe({
            next: async (res: any) => {
              const isChildEntity =
                res?.value?.data?.onCreateEntity?.childEntityId &&
                res?.value?.data?.onCreateEntity?.entityType === Entity_Type.CHILD_ENTITY;
              if (isChildEntity && this.subEntities) {
                this.disableEnableTabs(true, false, res);
                const indx = this.subEntities.findIndex(item => item.id === this.subEntityId);
                if (indx === -1) {
                  this.subEntityId = this.subEntities[0]?.id;
                }
                this.menuItems = await this.loadMenuItems(this.entityId, this.subEntityId);
              }
            },
          }) as any,
          this.api.OnDeleteEntityListener().subscribe({
            next: (res: any) => {
              const isChildEntity =
                res?.value?.data?.onDeleteEntity?.childEntityId &&
                res?.value?.data?.onDeleteEntity?.entityType === Entity_Type.CHILD_ENTITY;
              if (isChildEntity) {
                this.disableEnableTabs(false, true, res);
                const indx = this.subEntities.findIndex(item => item.id === this.subEntityId);
                if (indx === -1) {
                  this.subEntityId = this.subEntities[0]?.id;
                }
              }
            },
          }) as any,
          this.api.OnUpdateEntityListener().subscribe({
            next: (res: any) => {
              if (res?.value?.data?.onUpdateEntity) {
                this.disableEnableIntegrationTab(res.value.data.onUpdateEntity);
              }
            },
          }) as any,
          this.entityService.noArchivedFramework.subscribe({
            next: (res: any) => {
              this.menuItems.forEach(menuItem => {
                if (menuItem?.subMenu) {
                  menuItem?.subMenu.forEach(subItem => {
                    if (
                      subItem &&
                      subItem?.subItems &&
                      this.router?.url?.includes(SideNav.FIRST_PARTY) &&
                      this.router?.url?.includes(SideNav.COLLECTION)
                    ) {
                      if (subItem.subItems && subItem.subItems[2]) {
                        subItem.subItems[2].disabled = res;
                      }
                      this.archivedFramework = res;
                    }
                  });
                }
              });
            },
          }) as any,
          this.entityService.noArchivedRisk.subscribe({
            next: (res: boolean) => {
              this.menuItems.forEach(menuItem => {
                if (menuItem?.subMenu) {
                  menuItem?.subMenu.forEach(subItem => {
                    if (
                      subItem &&
                      subItem?.subItems &&
                      this.router?.url?.includes(SideNav.FIRST_PARTY) &&
                      this.router?.url?.includes(SideNav.RISK_REGISTER) &&
                      subItem?.name?.toLowerCase() === SideNav.RISK_REGISTER.split('-')?.join(' ')?.toLowerCase()
                    ) {
                      if (subItem.subItems && subItem.subItems[1]) {
                        subItem.subItems[1].disabled = res;
                        if (res) {
                          subItem.subItems[1].state.archive = false;
                          subItem.subItems[0].state.active = true;
                        }
                      }
                      this.archivedRisk = res;
                    }
                  });
                }
              });
            },
          }),
          this.entityService.isDataLoaded.subscribe({
            next: (res: any) => {
              this.menuItems.forEach(menuItem => {
                if (menuItem.name === '1st Party') {
                  menuItem?.subMenu.forEach(subItem => {
                    if (
                      subItem &&
                      subItem?.name === 'collection' &&
                      this.router?.url?.includes(SideNav.FIRST_PARTY) &&
                      this.router?.url?.includes(SideNav.COLLECTION) &&
                      subItem?.subItems[2]?.name === 'Archive'
                    ) {
                      const isArchive = this.route.snapshot.queryParams.archive;
                      if (!(isArchive === 'archive')) {
                        if (subItem.subItems && subItem.subItems[2] && !res) {
                          setTimeout(() => {
                            subItem.subItems[2].disabled = !res;
                          });
                        } else if (subItem.subItems && subItem.subItems[2] && res) {
                          setTimeout(() => {
                            subItem.subItems[2].disabled = this.entityService.foundArchived === false ? false : true;
                          });
                        }
                      } else {
                        if (subItem.subItems && subItem.subItems[0]) {
                          setTimeout(() => {
                            subItem.subItems[0].disabled = !res;
                          });
                        }
                      }
                    }
                  });
                }
              });
            },
          })
        );
      }

      this.showHideScroll();
      if (this.isScroll) {
        this.disableUpArrow = true;
        this.disableDownArrow = false;
      }
      if (this.isBnBCyberSite) {
        // revert to default value (false) for insurance.cegrc.com
        if (UtilsService.isInsuranceApp) {
          this.showBnbLogo = false;
        } else {
          this.showBnbLogo = !this.entityId;
        }
      } else if (this.isMidMarket) {
        this.showMidMarketLogo = !this.entityId;
      }

      // Need to disable both of them in ngOnInit because on changing navbar, it calls ngOnInit.
      this.disableUpArrow = true;
      this.disableDownArrow = true;
      setTimeout(() => {
        if (this.sideNav.nativeElement.clientHeight !== this.sideNav.nativeElement.scrollHeight) {
          this.disableUpArrow = this.sideNav.nativeElement.scrollHeight > this.sideNav.nativeElement.clientHeight;
          this.disableDownArrow = !(this.sideNav.nativeElement.scrollHeight > this.sideNav.nativeElement.clientHeight);

          this.moveScroll(
            this.sideNav.nativeElement,
            this.disableDownArrow ? this.arrowsEnum.DOWN : this.arrowsEnum.UP
          );
        }
      }, 500);
      if (!this.subEntities || !this.subEntities.length) {
        this.disableEnableTabs();
      }
      // else if (this.entityId && this.subEntities?.length > 7) {
      //   const isArchive = this.route.snapshot.queryParams.archive;
      //   if (isArchive) {
      //     this.menuItems[1]?.subMenu?.forEach(subMenuItem => {
      //       if (subMenuItem?.name === 'risk register') {
      //         subMenuItem.routerLink = [
      //           `first-party/${this.entityId}/risk-register`,
      //           { queryParams: { archive: 'archive' } },
      //         ];
      //       }
      //     });
      //   }
      //   // this.subEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'subEntityId');
      //   // const isAllSubEntityFilter = this.route.snapshot.queryParams?.isALL;
      //   // if (isAllSubEntityFilter && !UtilsService.isDefined(this.subEntityId)) {
      //   //   this.menuItems[1]?.subMenu?.forEach(subMenuItem => {
      //   //     if (subMenuItem?.name === 'risk register') {
      //   //       subMenuItem.routerLink = [`first-party/${this.entityId}/risk-register`];
      //   //       subMenuItem?.subItems?.forEach(subItem => {
      //   //         subItem.routerLink = [`first-party/${this.entityId}/risk-register`];
      //   //         if (subItem.name === 'Active') {
      //   //           subItem.queryParams = { isALL: true };
      //   //           subItem.state = { active: true };
      //   //         } else if (subItem.name === 'Archive') {
      //   //           subItem.queryParams = { isALL: true, archive: 'archive' };
      //   //           subItem.state = { archive: true };
      //   //         }
      //   //       });
      //   //     }
      //   //   });
      //   // }
      //   // else if (
      //   //   this.router.url.includes('risk-register') &&
      //   //   !isAllSubEntityFilter &&
      //   //   !UtilsService.isDefined(this.subEntityId)
      //   // ) {
      //   //   this.router.navigate([`first-party/${this.entityId}/risk-register/${this.subEntities[0]?.id}`]);
      //   // }
      //   else {
      //     // this.subEntityId = this.subEntities[0]?.id;
      //     // this.menuItems[1]?.subMenu?.forEach(subMenuItem => {
      //     //   if (subMenuItem?.name === 'risk register') {
      //     //     subMenuItem.routerLink = [`first-party/${this.entityId}/risk-register/${this.subEntityId}`];
      //     //     subMenuItem?.subItems?.forEach(subItem => {
      //     //       subItem.routerLink = [`first-party/${this.entityId}/risk-register/${this.subEntityId}`];
      //     //     });
      //     //   }
      //     // });
      //   }
      // }
      // Disabling Or Enable Integration Tab
      this.disableEnableIntegrationTab(this.entity);
      // Disabling risk register menu for Beecher instance
      if (UtilsService.isBnBCyberSite || this.isMidMarket) {
        this.disableRiskRegisterBeecher();
      }
      if (UtilsService.isMidMarket && this.isClient) {
        this.disableSidebarMenusForMidMarketClient();
      }
    } catch (e) {
      this.logger.error('SidenavComponent - Error: ', e);
    }
  }

  disableEnableIntegrationTab(updatedEntity?: any): any {
    this.menuItems.forEach(menuItem => {
      if (menuItem?.subMenu) {
        menuItem?.subMenu.forEach(subItem => {
          if (subItem.name.toLowerCase() === FirstPartyTabs?.INTEGRATIONS.toLowerCase()) {
            if (updatedEntity) {
              subItem.disabled = !this.checkIsAuthStatus(updatedEntity);
            }
          }
        });
      }
    });
  }

  /**
   * @param entity
   * @returns authStatus
   */
  checkIsAuthStatus(entity?: any): boolean {
    if (entity?.integrations?.length) {
      return entity.integrations.filter(item => item && item.isAuth).length ? true : false;
    }
  }

  /**
   * This function will disable those tabs that require sub-entity for their access
   * @param isEntityCreated,isEntityDeleted,subEntity
   */
  disableEnableTabs(isSubEntityCreated?: boolean, isSubEntityDeleted?: boolean, subEntities?: any): void {
    const tabsToDisable = ['collection', 'pillars', 'break down'];
    if (isSubEntityCreated) {
      const parentId = subEntities.value.data.onCreateEntity.parentId;
      if (parentId && parentId === this.entityId) {
        this.subEntities.push(subEntities.value.data.onCreateEntity);
        this.menuItems.forEach(menuItem => {
          if (menuItem.subMenu) {
            menuItem?.subMenu.forEach(subItem => {
              if (tabsToDisable.includes(subItem.name.toLowerCase()) && this.router.url.includes(SideNav.FIRST_PARTY)) {
                subItem.disabled = false;
              }
            });
          }
        });
      }
    } else if (isSubEntityDeleted) {
      const id = subEntities.value.data.onDeleteEntity.id;
      const parentId = subEntities.value.data.onDeleteEntity.parentId;
      if (parentId && parentId === this.entityId) {
        this.subEntities = this.subEntities.filter(subEntity => {
          return subEntity.id !== id;
        });
        this.menuItems.forEach(menuItem => {
          if (menuItem.subMenu) {
            menuItem?.subMenu.forEach(subItem => {
              if (tabsToDisable.includes(subItem.name.toLowerCase()) && !this.subEntities.length) {
                subItem.disabled = true;
              }
            });
          }
        });
      }
    } else {
      this.menuItems.forEach(menuItem => {
        if (menuItem.subMenu) {
          menuItem?.subMenu.forEach(subItem => {
            if (tabsToDisable.includes(subItem.name.toLowerCase())) {
              subItem.disabled = true;
            }
          });
        }
      });
    }
  }

  /**
   * Disabling Risk Register Menu for Beecher instance
   */
  disableRiskRegisterBeecher(): void {
    //* Disable Risk Register for MidMarket and Beecher
    const menuItem1 = this.menuItems?.[1];
    const subMenu7 = menuItem1?.subMenu?.[7];
    const subMenu1 = menuItem1?.subMenu?.[1];

    if (subMenu7) {
      subMenu7.disabled = UtilsService.isBnBCyberSite || this.isMidMarket; //* Disable Risk Register for MidMarket and Beecher
    }

    if (subMenu1?.subItems?.[1]) {
      //* Hide Smart Mapping Tab from sidenav for Beecher/MidMarket
      subMenu1.subItems[1].hidden = UtilsService.isBnBCyberSite || UtilsService.isMidMarket;
    }
  }

  /**
   * Function disables 'controls' for Midmarket client
   */
  disableSidebarMenusForMidMarketClient(): void {
    const menuItem1 = this.menuItems?.[1];
    const subMenu1 = menuItem1?.subMenu?.[1];
    const subMenu2 = menuItem1?.subMenu?.[2];
    const subMenu4 = menuItem1?.subMenu?.[4];

    if (subMenu1 && subMenu1.subItems?.[1]) {
      subMenu1.subItems[1].disabled = true; //* Smart Mapping
    }
    if (subMenu2 && subMenu2.subItems?.[1]) {
      subMenu2.subItems[1].disabled = true; //* Group View
    }
    if (subMenu4) {
      subMenu4.disabled = true; //* Controls Menu
    }
  }

  subscribeToRouteChange(): void {
    this.subscriptionList.push(
      this.router.events.subscribe(val => {
        if (val instanceof NavigationEnd) {
          this.ngOnInit();
        }
      })
    );
  }

  async formMenu(domainMenu = [], isDomainSpecificMenu = false): Promise<any> {
    const basicMenu = await this.loadMenuItems(this.entityId, this.subEntityId);
    if (isDomainSpecificMenu) {
      basicMenu.forEach(element => {
        const changedMenu = domainMenu.find(item => item?.name === element?.name);
        if (changedMenu) {
          element.routerLink = changedMenu.routerLink ? changedMenu.routerLink : element.routerLink;
          element.disabled = changedMenu.disabled;
        } else {
          element.hidden = true;
          element.disabled = true;
        }
        if (element.subMenu && changedMenu && changedMenu.subMenu) {
          element.subMenu = [...changedMenu.subMenu];
        }
      });
    }
    this.formMenuItemsVisibility(basicMenu);
    return basicMenu;
  }

  formMenuItemsVisibility(menu = []): void {
    menu.forEach(menuItem => {
      const menuRoute =
        menuItem.routerLink && menuItem.routerLink[0].includes('/')
          ? menuItem.routerLink[0].split('/')[0]
          : menuItem.routerLink[0];
      if (this.userService.currentUserRole && this.userService.currentUserRole.screenPermissions) {
        const parsedPermissions = JSON.parse(this.userService.currentUserRole.screenPermissions);
        // menuItem.disabled = false;
        if (menuItem.subMenu) {
          menuItem.subMenu.forEach((subItem, index) => {
            if (subItem.routerLink && subItem.routerLink.length) {
              const subRouteArray = subItem.routerLink[0].split('/');
              let subRoute = subRouteArray[subRouteArray.length - 1];

              // hotfix, need to find proper solutions for clients bnB
              if (menuRoute && menuRoute.includes('clients')) {
                subItem.hidden = !parsedPermissions['basic-routes']?.includes(menuRoute);
                if (
                  subItem.name === UsersSettingConstant.userSettings &&
                  (UtilsService.isBnB || UtilsService.isBnBCyberSite || this.isMidMarket)
                ) {
                  subItem.disabled = false;
                } else {
                  subItem.disabled =
                    !parsedPermissions['basic-routes']?.includes(menuRoute) ||
                    this.currentRole?.name.toLowerCase() === AppLevelRoleEnumBnB.USER_ADMINISTRATOR.toLowerCase() ||
                    (parsedPermissions['basic-routes']?.includes(menuRoute) &&
                      index !== 0 &&
                      this.conditionalSubMenuBnb(index + 1));
                  if (subItem.name) {
                    switch (subItem.name.toLowerCase()) {
                      case ClientsScreenTitles.ENTERPRISE.toLowerCase():
                        subItem.hidden = this.isDivLea || this.isRegLea;
                        break;
                      case ClientsScreenTitles.DIVISION.toLowerCase():
                        subItem.hidden = this.isRegLea;
                    }
                  }
                }
              } else {
                if (subRoute === 'default-settings') {
                  subRoute = subRoute.split('-').join(' ');
                }
                subItem.hidden = !parsedPermissions[menuRoute]?.includes(subRoute);
                subItem.disabled = !parsedPermissions[menuRoute]?.includes(subRoute);
                // TODO: remove this if condition after Risk Register optimization
                if (
                  subRouteArray.includes('risk-register') &&
                  (this.subEntities.length > 7 || this.currentRole.name === AppLevelRoleEnum.SUBENTITY_LEADER)
                ) {
                  subItem.hidden = !parsedPermissions[menuRoute]?.includes('risk-register');
                  subItem.disabled = !parsedPermissions[menuRoute]?.includes('risk-register');
                }
              }
            }
          });
        }
      } else if (this.currentRole && this.currentRole?.name?.toLowerCase() === AppLevelRoleEnum.VENDOR) {
        menuItem.disabled = false;
        menuItem.hidden = false;
      } else {
        menuItem.hidden = true;
        menuItem.disabled = true;
      }
    });
  }
  /**
   * function to return
   * Parent ID
   */
  async getParentId() {
    const entity = await this.userService.getCurrentUserEntity(this.userService.currentUser.entityIds[0]);
    if (entity.entityType === EntityTypeEnum.ROOT_ENTITY || entity.entityType === EntityTypeEnum.VENDOR) {
      return entity.id;
    } else {
      return entity.parentId;
    }
  }
  async loadMenuItems(entityId: string, subEntityId: string): Promise<any[]> {
    try {
      if (!UtilsService.isDefined(this.currentRole)) {
        return [];
      }
      if (
        this.currentRole?.name !== AppLevelRoleEnum.ADMIN &&
        this.currentRole?.name !== AppLevelRoleEnum.MSSP &&
        this.currentRole?.name !== AppLevelRoleEnumBnB.USER_ADMINISTRATOR
      ) {
        entityId =
          this.currentRole?.defaultOrEntityId === 'default' ? await this.getParentId() : this.currentRole.entityId;
      } else {
        entityId = UtilsService.isDefined(entityId) ? entityId : '';
      }
      subEntityId = UtilsService.isDefined(subEntityId) ? subEntityId : '';
      const vendorId = this.isVendorLeader ? subEntityId : '';
      const isBnbBeecher = this.isBnBCyberSite || this.isBnB || this.isMidMarket;
      const isEnterprise = this.currentRole?.name === AppLevelRoleEnumBnB.ENTERPRISE;
      const baseMenu = [
        {
          name: 'Management',
          routerLink: ['clients'],
          svgName: 'clients-icon',
          hidden: this.isVendorLeader || !this.permissions?.['basic-routes']?.includes('clients'),
          disabled: this.isWelcome || !this.permissions?.['basic-routes']?.includes('clients'),
          click: () => {
            this.router.navigate(['clients']);
          },
        },
        {
          name: '1st Party',
          routerLink: ['first-party', entityId],
          svgName: '',
          // hidden: !entityId || this.isVendorLeader || !this.permissions?.['first-party'],
          hidden: this.isVendorLeader,
          disabled: !entityId || this.isVendorLeader || !this.permissions?.['first-party'],
          // disabled: !entityId || !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
          subMenu: [
            {
              name: 'upperdeck',
              routerLink: [`first-party/${entityId}/upperdeck`],
              svgName: 'binoculars',
              disabled: !this.permissions?.['first-party']?.includes('upperdeck'),
              // hidden: !this.permissions?.['first-party']?.includes('upperdeck'),
              // disabled: !entityId || !(await this.authService.hasPermission(RoleEnum.LEADER, entityId)),
            },
            {
              name: 'collection',
              routerLink: this.getRouterLinkByRole(`first-party/${entityId}/collection`, subEntityId),
              svgName: 'collection-icon',
              // hidden: !this.permissions?.['first-party']?.includes('collection'),
              disabled: !this.permissions?.['first-party']?.includes('collection'),
              // disabled: !entityId || !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
              // Added sub menu for collection screen; active & archive
              subItems: [
                {
                  name: 'Active',
                  routerLink: this.getRouterLinkByRole(
                    `first-party/${entityId}/collection/${subEntityId}`,
                    subEntityId
                  ),
                  // queryParams: { active: 'active' },
                  state: { active: true },
                  svgName: '',
                  disabled: !this.permissions?.['first-party']?.includes('collection'),
                },
                {
                  name: 'Smart Mapping',
                  routerLink: [`first-party/${entityId}/collection/smartmapped`],
                  state: { smartmapped: true },
                  svgName: '',
                  disabled: !this.permissions?.['first-party']?.includes('collection'),
                },
                {
                  name: 'Archive',
                  routerLink: this.getRouterLinkByRole(
                    `first-party/${entityId}/collection/${subEntityId}`,
                    subEntityId
                  ),
                  queryParams: { archive: 'archive' },
                  state: { archive: true },
                  svgName: '',
                  disabled: !UtilsService.isDefined(this.archivedFramework) ? true : this.archivedFramework,
                },
              ],
            },
            {
              name: 'multi entity',
              routerLink: this.getRouterLinkByRole(`first-party/${entityId}/multi-entity`, subEntityId),
              svgName: 'multi-entity-icon',
              // hidden: !this.permissions?.['first-party']?.includes('multi-entity'),
              disabled: !this.permissions?.['first-party']?.includes('multi-entity'),
              // disabled: !entityId || !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
              subItems: [
                {
                  name: 'Entity View',
                  routerLink: [`first-party/${entityId}/multi-entity`],
                  svgName: '',
                },
                {
                  name: 'Group View',
                  routerLink: [`first-party/${entityId}/multi-entity/group`],
                  svgName: '',
                },
              ],
            },

            {
              name: 'reporting',
              routerLink: this.getRouterLinkByRole(`first-party/${entityId}/pillars`, subEntityId),
              svgName: 'pillars-icon',
              // hidden: !this.permissions?.['first-party']?.includes('pillars'),
              disabled: !this.permissions?.['first-party']?.includes('pillars'),
              // disabled: !entityId || !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
              subItems: [
                {
                  name: 'Pillars',
                  routerLink: [`first-party/${entityId}/pillars`],
                  svgName: '',
                },
                {
                  name: 'Graphs',
                  routerLink: [`first-party/${entityId}/pillars/graphs`],
                  svgName: '',
                },
              ],
            },
            {
              name: 'controls',
              routerLink: this.getRouterLinkByRole(`first-party/${entityId}/break-down`, subEntityId),
              svgName: 'controls-icon',
              disabled: !this.permissions?.['first-party']?.includes('break-down'),
              subItems: [
                {
                  name: 'table',
                  routerLink: this.getRouterLinkByRole(`first-party/${entityId}/break-down`, subEntityId),
                  svgName: 'break-down-icon',
                  disabled: !this.permissions?.['first-party']?.includes('break-down'),
                },
                {
                  name: 'navigator',
                  routerLink: this.getRouterLinkByRole(`first-party/${entityId}/break-down/navigator`, subEntityId),
                  svgName: 'break-down-icon',
                  disabled: !this.permissions?.['first-party']?.includes('break-down'),
                },
              ],
            },
            {
              name: 'remediation',
              routerLink: this.getRouterLinkByRole(`first-party/${entityId}/remediation`, subEntityId),
              svgName: 'remediation-icon',
              // hidden: !this.permissions?.['first-party']?.includes('remediation'),
              disabled: !this.permissions?.['first-party']?.includes('remediation'),
              // disabled: !entityId || !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
            },
            {
              name: 'users settings',
              svgName: 'settings-icon',
              routerLink: this.getRouterLinkByRole(`first-party/${entityId}/user-settings`, subEntityId),
              // hidden: !this.permissions?.['first-party']?.includes('user-settings'),
              disabled: !this.permissions?.['first-party']?.includes('user-settings'),
              // disabled: !entityId || !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
            },
            {
              name: 'risk register',
              routerLink: this.getRiskRegisterRoute(entityId),
              svgName: 'risk-register-icon',
              // hidden: !this.permissions?.['first-party']?.includes('risk-register'),
              disabled: !this.permissions?.['first-party']?.includes('risk-register'),
              // disabled: !entityId || !(await this.authService.hasPermission(RoleEnum.LEADER, entityId)),
              subItems: [
                {
                  name: 'Active',
                  routerLink: this.getRiskRegisterRoute(entityId),
                  state: { active: true },
                  svgName: '',
                  disabled: !this.permissions?.['first-party']?.includes('risk-register'),
                },
                {
                  name: 'Archive',
                  routerLink: this.getRiskRegisterRoute(entityId),
                  queryParams: { archive: 'archive' },
                  state: { archive: true },
                  svgName: '',
                  disabled: !UtilsService.isDefined(this.archivedRisk) ? true : this.archivedRisk,
                },
              ],
            },
            {
              name: 'Integrations',
              routerLink: [`first-party/${entityId}/integrations`],
              svgName: 'new-integration-icon',
              // hidden: !this.permissions?.['first-party']?.includes('risk-register'),
              disabled: false,
              // disabled: !entityId || !(await this.authService.hasPermission(RoleEnum.LEADER, entityId)),
            },
            // {
            //   name: 'Attack Simulator',
            //   routerLink: [`first-party/${entityId}/attack-simulator`],
            //   svgName: 'attack-simulator-icon',
            //   disabled: true,
            // },
            // {
            //   name: 'alerts',
            //   routerLink: [`first-party/${entityId}/alerts`],
            //   svgName: 'alerts-icon',
            //   disabled: true, // TODO: this route should be showed also without entityId
            // },
            // {
            //   name: 'admin',
            //   routerLink: this.getRouterLinkByRole(
            //     `first-party/${entityId}/questionnaire-management`,
            //     subEntityId
            //   ),
            //   svgName: 'admin-icon',
            //   hidden: this.isCrbAdjustments,
            //   disabled:
            //     !entityId ||
            //     !(await this.authService.hasPermission(RoleEnum.LEADER, entityId, subEntityId)),
            // },
            // {
            //   name: 'questionnaire',
            //   routerLink: [`first-party/${entityId}/questionnaire`, subEntityId],
            //   svgName: 'admin-icon',
            //   hidden: !this.isCrbAdjustments,
            //   disabled: !(entityId && subEntityId),
            // },
          ],
        },
        // Third-party Menu
        {
          name: this.isHowden ? 'Cyber Insurance' : '3rd Party',
          routerLink: ['third-party', entityId],
          svgName: '',
          // hidden: !entityId || this.isVendorLeader || !this.permissions?.['third-party'],
          hidden: this.isVendorLeader || (isBnbBeecher && !isEnterprise),
          disabled:
            !entityId || this.isVendorLeader || !this.permissions?.['third-party'] || (isBnbBeecher && isEnterprise),
          // disabled:
          //   environment.disable3rdPartyModule ||
          //   !entityId ||
          //   !(await this.authService.hasPermission(RoleEnum.LEADER, entityId)),
          subMenu: [
            {
              name: 'Overview',
              svgName: 'overview-icon',
              // hidden: this.isVendorLeader || !this.permissions?.['third-party']?.includes('overview'),
              disabled: this.isVendorLeader || !this.permissions?.['third-party']?.includes('overview'),
              routerLink: [`third-party/${entityId}/overview`],
              // disabled: this.isVendorLeader,
            },
            {
              name: 'Heatmap',
              svgName: 'heatmap-icon',
              // hidden: this.isVendorLeader || !this.permissions?.['third-party']?.includes('heat-map'),
              disabled: this.isVendorLeader || !this.permissions?.['third-party']?.includes('heat-map'),
              routerLink: [`/third-party/${entityId}/heat-map`],
              // disabled: this.isVendorLeader,
            },
            {
              name: this.isHowden ? 'Clients' : 'Vendors',
              svgName: 'vendors-icon', // if changing the svg name, make sure to change condition in html file.
              routerLink: [`third-party/${entityId}/vendors`],
              disabled: false,
              state: { active: true },

              subItems: [
                {
                  name: 'Collection',
                  routerLink: [
                    `third-party/${entityId}/collection/${
                      this.route.snapshot.queryParams.vendorId
                        ? this.route.snapshot.queryParams.vendorId
                        : this.thirdPartyService.vendorId
                    }`,
                  ],
                  svgName: '',
                  hidden: !this.route.snapshot.queryParams.vendorId && !this.isFromThirdPartyCollection,
                },
                {
                  name: 'Remediation',
                  routerLink: [
                    `third-party/${entityId}/gaps/${
                      this.route.snapshot.queryParams.vendorId
                        ? this.route.snapshot.queryParams.vendorId
                        : this.thirdPartyService.vendorId
                    }`,
                  ],
                  svgName: '',
                  hidden: !this.route.snapshot.queryParams.vendorId && !this.isFromThirdPartyCollection,
                },
              ],
            },
            {
              name: 'Settings',
              svgName: 'settings-icon',
              routerLink: [`third-party/${entityId}/default-settings`],
              hidden: this.isVendorLeader || !this.permissions?.['third-party']?.includes('default settings'),
              id: 'defaultSettingModal',
              click: (): void => {
                this.openDefaultSettingModal();
              },
              disabled: this.isVendorLeader,
            },
          ],
        },
        {
          name: 'Boardview',
          routerLink: [this.board, entityId],
          svgName: '',
          // hidden: !entityId || this.isVendorLeader || !this.permissions?.board,
          hidden: this.isVendorLeader || (isBnbBeecher && !isEnterprise),
          disabled: !entityId || this.isVendorLeader || !this.permissions?.board || (isBnbBeecher && isEnterprise),
          // disabled:
          //   environment.disableBoardModule ||
          //   !entityId ||
          //   !(await this.authService.hasPermission(RoleEnum.LEADER, entityId)),
          subMenu: [
            {
              name: 'Overview',
              svgName: 'overview-icon',
              // hidden: this.isVendorLeader || !this.permissions?.board?.includes('overview'),
              disabled: this.isVendorLeader || !this.permissions?.board?.includes('overview'),
              // disabled: this.isWizard && !this.doesReportExist,
              routerLink: [`/${this.board}/${entityId}/overview`],
            },
            {
              name: 'Risk Score',
              svgName: 'risk-score-icon',
              // hidden: this.isVendorLeader || !this.permissions?.board?.includes('risk-score'),
              disabled: this.isVendorLeader || !this.permissions?.board?.includes('risk-score'),
              // disabled: this.isWizard && !this.doesReportExist,
              routerLink: [`/${this.board}/${entityId}/risk-score`],
            },
            {
              name: 'Compliance',
              svgName: 'compliance-icon',
              // hidden: this.isVendorLeader || !this.permissions?.board?.includes('compliance'),
              disabled: this.isVendorLeader || !this.permissions?.board?.includes('compliance'),
              // disabled: this.isWizard && !this.doesReportExist,
              routerLink: [`/${this.board}/${entityId}/compliance`],
            },
            {
              name: 'Threat Level',
              svgName: 'threat-level-icon',
              // hidden: this.isVendorLeader || !this.permissions?.board?.includes('threat-level'),
              disabled: this.isVendorLeader || !this.permissions?.board?.includes('threat-level'),
              // disabled: this.isWizard && !this.doesReportExist,
              routerLink: [`/${this.board}/${entityId}/threat-level`],
            },
            {
              name: 'Monitoring',
              svgName: 'monitoring-icon',
              // hidden: this.isVendorLeader || !this.permissions?.board?.includes('monitoring'),
              disabled: this.isVendorLeader || !this.permissions?.board?.includes('monitoring'),
              // disabled: this.isWizard && !this.doesReportExist,
              routerLink: [`/${this.board}/${entityId}/monitoring`],
            },
            {
              name: 'Operational',
              svgName: 'operational-icon',
              // hidden: this.isVendorLeader || !this.permissions?.board?.includes('operational'),
              disabled: this.isVendorLeader || !this.permissions?.board?.includes('operational'),
              //  disabled: this.isWizard && !this.doesReportExist,
              routerLink: [`/${this.board}/${entityId}/operational`],
            },
            {
              name: 'Wizard',
              svgName: 'wizard-icon',
              // hidden: this.isVendorLeader || !this.permissions?.board?.includes('wizard'),
              disabled: this.isVendorLeader || !this.permissions?.board?.includes('wizard'),
              routerLink: [`/${this.board}/${entityId}/wizard`],
            },
            {
              name: 'Reports Archive',
              svgName: 'reports-archive-icon',
              //  disabled: this.isWizard && !this.doesReportExist,
              // hidden: this.isVendorLeader || !this.permissions?.board?.includes('reports-archive'),
              disabled: this.isVendorLeader || !this.permissions?.board?.includes('reports-archive'),
              routerLink: [`/${this.board}/${entityId}/reports-archive`],
            },
          ],
        },
        {
          name: 'Training Center',
          routerLink: ['training-center'],
          hidden: this.isVendorLeader || !this.permissions?.['basic-routes']?.includes('training-center'),
          svgName: 'attack-simulator-icon',
          // disabled: environment.disableTrainingCtr,
          subMenu: [
            {
              name: 'Management',
              svgName: 'management-icon',
              hidden: this.isVendorLeader || !this.permissions?.['training-center']?.includes('management'),
              routerLink: [`/${this.trainingCenter}/management`],
            },
            {
              name: '1st Party',
              svgName: 'first-party-training-icon',
              hidden: this.isVendorLeader || !this.permissions?.['training-center']?.includes('first-party'),
              routerLink: [`/${this.trainingCenter}/first-party`],
            },
            {
              name: this.isHowden ? 'Cyber Insurance' : '3rd Party',
              svgName: 'third-party-training-icon',
              hidden:
                this.isVendorLeader || !this.permissions?.['training-center']?.includes('third-party') || isBnbBeecher,
              routerLink: [`/${this.trainingCenter}/third-party`],
            },
            {
              name: 'Boardview',
              svgName: 'board-training-icon',
              hidden: this.isVendorLeader || !this.permissions?.['training-center']?.includes('board'),
              routerLink: [`/${this.trainingCenter}/board`],
            },
          ],
        },
        {
          name: 'Settings',
          routerLink: ['clients/user-settings'],
          hidden:
            isBnbBeecher ||
            this.isVendorLeader ||
            !this.isAdminOrMssp ||
            !this.permissions?.['basic-routes']?.includes('training-center'),
          svgName: '',
        },
        {
          name: 'collection',
          routerLink: this.getRouterLinkByRole(`first-party/${entityId}/collection`, vendorId),
          queryParams: { userId: this.user.id },
          svgName: 'admin-icon',
          hidden: !this.isVendorLeader,
          disabled: !(entityId && vendorId),
        },
        {
          name: 'Gaps',
          routerLink: this.getRouterLinkByRole(`vendor/${entityId}/gaps`, vendorId),
          svgName: 'remediation-center-icon',
          hidden: !this.isVendorLeader,
          disabled:
            !entityId ||
            !vendorId ||
            !this.userService.hasPermissionHierarchy(AppLevelRoleEnum.VENDOR, [entityId], vendorId),
        },
      ];

      // NEED TO REMOVE THIS AND PUT IT INTO ITS RESPECTIVE PLACE LIKE NETSKOPE ROUTES
      if (UtilsService.isBnB) {
        baseMenu[0].subMenu = this.getSubMenuClients();
      }
      if (UtilsService.isBnBCyberSite || this.isMidMarket) {
        baseMenu[0].subMenu = this.getSubMenuForBeecher();
      }

      return baseMenu;
    } catch (e) {
      console.log('ERROR : ', e);
    }
  }

  loadMenuItemsForParticipant(entityId: string, subEntityId: string): any {
    return [
      {
        name: 'collection',
        routerLink: this.getRouterLinkByRole(`first-party/${entityId}/collection`, subEntityId),
        svgName: 'collection-icon',
        disabled:
          !entityId || !this.userService.hasPermissionHierarchy(AppLevelRoleEnum.PARTICIPANT, [entityId], subEntityId),
      },
      {
        name: 'Remediation',
        routerLink: this.getRouterLinkByRole(`first-party/${entityId}/remediation`, subEntityId),
        svgName: 'remediation-center-icon',
        disabled:
          !entityId || !this.userService.hasPermissionHierarchy(AppLevelRoleEnum.PARTICIPANT, [entityId], subEntityId),
      },
    ];
  }

  getRouterLinkByRole(route: string, subEntityId: string): string[] {
    const routerLink: string[] = [route];

    if (!this.isAdminOrMssp && !this.isEntityLeader && !this.isSubEntityLeader) {
      routerLink.push(subEntityId);
    }

    return routerLink;
  }

  onMenuClicked(): void {
    this.showMenu = !this.showMenu;
    this.showHideScroll();
  }

  openDefaultSettingModal(): void {
    /**
     * Default settings works well in all scenario except when a vendor is selected. So adding same route to avoid reload
     */
    if (this.router.url.includes('vendorId')) {
      this.router.navigate([`third-party/${this.entityId}/vendors`], {
        queryParams: { vendorId: this.thirdPartyService.vendorId },
      });
    }
    if (this.modalReference == null) {
      // this.defaultSettingSubMenuClass = 'defaultSettingModalActive';
      // this.subMenuClass = 'subMenuClassDefaultSettingActive';
      // here we decide either to show new modal or old one
      // 1 - old : DefaultSettingModalComponent
      // 2. - New : DefaultSettingsNewModalComponent
      this.modalReference = this.modalService.open(DefaultSettingsNewModalComponent, {
        centered: true,
        size: 'lg',
        windowClass: 'default-setting-modal',
        backdropClass: 'default-setting-backdrop',
        backdrop: 'static',
        animation: false,
      });
      this.modalReference.componentInstance.closeDefaultModal.subscribe(() => {
        this.closeModal();
      });
    } else {
      this.closeModal();
    }
  }

  activeRouteCaseVendorMenu(): boolean {
    return (
      (this.currentRoutePath?.includes('gaps') || this.currentRoutePath?.includes('collection')) &&
      this.currentRoutePath?.includes('third-party')
    );
  }
  closeModal(): void {
    if (this.modalReference != null) {
      this.modalReference.close();
      this.modalReference = null;
      // this.defaultSettingSubMenuClass = 'defaultSettingModalNotActive';
      this.subMenuClass = '';
    }
  }

  // Navigation Method
  moveToBoardPage(menuItem): void {
    // For board Module Side menu links
    if (menuItem && menuItem.routerLink && menuItem.routerLink.some(link => link.includes('board/'))) {
      const pageName = menuItem?.name.toLowerCase().split(' ').join('-');
      this.boardService.newBreadCrumbs = breadcrumbLinks[pageName];
      this.router.navigate(this.boardService.generateUrlWithBaseUrl(pageName));
    } else {
      if (this.router.url.includes('vendorId')) {
        // for vendorId, cannot navigate with [] empty array when settings are clicked.
        return;
      }
      this.router.navigate(menuItem && menuItem.routerLink && menuItem.routerLink.length ? menuItem.routerLink : []);
    }
  }

  ngOnDestroy(): void {
    this.entity = null;
    this.subscriptionList.forEach(listener => listener.unsubscribe());
  }

  /**
   * @param element is type HTMLElement on which the Scroll is added
   * @param moveDirection is type of ArrowEnum either 'up' or 'down' string
   */
  moveScroll(element: HTMLElement, moveDirection: string): void {
    const itemHeight = element.offsetHeight / 13; // One menu-item height
    const range = 4 * itemHeight + 25; // range contains 4 menu-items height size to move the 4 menu-items up or down
    if (
      moveDirection.toLowerCase() === ArrowsEnum.UP.toLowerCase() &&
      !this.isFirstTime &&
      this.isDownClicked &&
      element.scrollTop === 0
    ) {
      this.disableDownArrow = false;
      this.disableUpArrow = true;
    } else if (
      moveDirection.toLowerCase() === ArrowsEnum.UP.toLowerCase() &&
      !this.isFirstTime &&
      !this.isDownClicked &&
      element.scrollTop === 0
    ) {
      this.disableDownArrow = false;
      this.disableUpArrow = true;
    } else if (moveDirection.toLowerCase() === ArrowsEnum.UP.toLowerCase() && !this.disableUpArrow) {
      if (element.scrollTop - range >= 0) {
        element.scrollTop = element.scrollTop - range;
        this.disableUpArrow = false;
        this.disableDownArrow = false;
        this.isDownClicked = !this.isDownClicked;
      } else {
        element.scrollTop = 0;
        this.disableDownArrow = false;
        this.disableUpArrow = true;
        this.isDownClicked = !this.isDownClicked;
      }
    } else if (
      moveDirection.toLowerCase() === ArrowsEnum.DOWN.toLowerCase() &&
      this.disableUpArrow &&
      !this.isFirstTime
    ) {
      element.scrollTop = element.scrollTop + range;
      this.disableUpArrow = false;
      this.disableDownArrow = true;
      this.isDownClicked = true;
    } else if (moveDirection.toLowerCase() === ArrowsEnum.UP.toLowerCase() && !this.isFirstTime) {
      this.disableDownArrow = true;
      this.disableUpArrow = false;
      this.isDownClicked = !this.isDownClicked;
    } else if (moveDirection.toLowerCase() === ArrowsEnum.DOWN.toLowerCase() && !this.disableDownArrow) {
      element.scrollTop = element.scrollTop + range;
      if (element.scrollTop <= element.scrollHeight - element.offsetHeight) {
        this.disableDownArrow = true;
        this.disableUpArrow = false;
        this.isDownClicked = true;
      } else {
        element.scrollTop = element.scrollHeight;
        this.disableUpArrow = false;
        this.isDownClicked = true;
      }
    }
    this.isFirstTime = false;
  }

  /**
   *  either show or hide the up and down arrow icons
   */
  showHideScroll(): void {
    this.isScroll = this.sideNav.nativeElement.clientHeight > this.sideNav.nativeElement.scrollHeight;
  }

  /**
   * Side Nav Client Functionality for Brown and Brown
   */
  conditionalSubMenuBnb(layerLevel: number): boolean {
    const layerName = this.router.url.split('/').pop();
    if (layerName === ClientSubMenuEnum.ENTERPRISE) {
      return true;
    } else if (layerName === ClientSubMenuEnum.USERSETTINGS) {
      if (this.isDivLea && layerLevel === 2) {
        return false;
      }
      if (this.isRegLea && layerLevel === 3) {
        return false;
      }
      return true;
    } else {
      return layerLevel > this.layerPriority[layerName.toUpperCase()];
    }
  }
  /**
   * returns the sub-menu options for Management Menu for BnB
   */
  getSubMenuClients(): any[] {
    const subMenu = [
      {
        name: 'Enterprise',
        routerLink: ['clients/enterprise'],
        svgName: 'bnb-enterprise',
        disabled: !this.permissions?.clients?.includes('enterprise'),
        hidden: this.isDivLea || this.isRegLea,
      },
      {
        name: 'Division Summary',
        routerLink: ['clients/division'],
        svgName: 'bnb-division',
        disabled: !this.permissions?.clients?.includes('division') && this.conditionalSubMenuBnb(2),
        hidden: this.isRegLea,
      },
      {
        name: 'Region Summary',
        routerLink: ['clients/region'],
        svgName: 'bnb-region',
        disabled: !this.permissions?.clients?.includes('region') && this.conditionalSubMenuBnb(3),
      },
      {
        name: 'Region Breakdown',
        routerLink: ['clients/breakdown'],
        svgName: 'bnb-breakdown',
        disabled: !this.permissions?.clients?.includes('breakdown') && this.conditionalSubMenuBnb(4),
      },
      {
        name: 'User Settings',
        svgName: 'settings-icon',
        routerLink: ['clients/user-settings'],
        disabled: !this.permissions?.clients?.includes('user-settings'),
      },
    ];
    return subMenu;
  }

  getSubMenuForBeecher(): any[] {
    const subMenu = [
      {
        name: 'User Settings',
        svgName: 'settings-icon',
        routerLink: ['clients/user-settings'],
        disabled: !this.permissions?.clients?.includes('user-settings'),
      },
    ];
    return subMenu;
  }

  // Hotfix: First multi-entity will be selected as default if having more than 7 multi-entities.
  // NOTE: All the changes will be revered back against this commit after the optimization of the risk register.
  // Actually, there exists almost 250 multi-entities and thousands of risks against them.
  // TODO: remove this function after Risk Register optimization
  getRiskRegisterRoute(entityId: string): string[] {
    if (!entityId) {
      return null;
    }

    let subEntityId: string = this.subEntityId ? this.subEntityId : this.subEntities[0]?.id;
    if (this.currentRole.name === AppLevelRoleEnum.SUBENTITY_LEADER) {
      subEntityId = this.user.entityId;
      return [`first-party/${entityId}/risk-register/${subEntityId}`];
    } else {
      return [`first-party/${entityId}/risk-register`];
    }
    // commenting this code for skipping 7 length check.
    // else if (this.subEntities.length > 7) {
    //   return [`first-party/${entityId}/risk-register/${subEntityId}`]; }
  }

  /**
   * This function will disable or Enable Integration Tab
   * on basis of current entity auth status in Integration array
   * @param updatedEntity
   */
  skipLocation(): boolean {
    if (
      this.router.url.includes('first-party') &&
      this.router.url.includes('collection') &&
      !this.router.url.includes('smartmapped')
    ) {
      return true;
    }
    return false;
  }
}
