// FrameworkLevelBackend Enum
export enum FrameworkLevelBackend {
  // Vendor_HECVAT_Lite = 'VENDOR_RISK_DBU_HECVAT_LITE',

  Vendor_SAQ_Tier_1 = 'VENDOR_RISK_INFORMATICA_1',
  Vendor_SAQ_Tier_2 = 'VENDOR_RISK_INFORMATICA_2',
  Vendor_SAQ_Tier_3 = 'VENDOR_RISK_INFORMATICA_3',
  OSAE_Full = 'VENDOR_RISK_OSAE',

  VENDOR_RISK_HOWDEN_Cyber_360 = 'VENDOR_RISK_HOWDEN_CYBER_360',
  VENDOR_RISK_HOWDEN_Ransomware = 'VENDOR_RISK_HOWDEN_RANSOMWARE',
  VENDOR_RISK_HOWDEN_Old = 'VENDOR_RISK_HOWDEN',
  VENDOR_RISK_HOWDEN_Full = 'VENDOR_RISK_HOWDEN_FULL',

  VENDOR_RISK_ITC_Full = 'VENDOR_RISK_ITC',

  VENDOR_RISK_CRB_Full = 'VENDOR_RISK_CRB',

  BA_HIPPA_Full = 'VENDOR_RISK_BA_HIPPA',
  VENDOR_RISK_SSM_HEALTH_Full = 'VENDOR_RISK_SSM_HEALTH',

  VENDOR_RISK_ENBD_Full = 'VENDOR_RISK_ENBD',
  VENDOR_RISK_SAQ_LITE_Full = 'VENDOR_RISK_ENBD_SAQ_LITE',
  VENDOR_RISK_SOC2_Full = 'VENDOR_RISK_ENBD_SOC2',

  VENDOR_SAQ_Full = 'VENDOR_RISK_SAQ',
  VENDOR_SAQ_V2 = 'VENDOR_RISK_SAQ_V2',
  VENDOR_PRE_SAQ_Full = 'VENDOR_RISK_PRESAQ',
  VENDOR_PRE_SAQ_V2 = 'VENDOR_RISK_PRESAQ_V2',

  VENDOR_RISK_MOELIS_Full = 'VENDOR_RISK_MOELIS',

  Centeraleyes_SAQ_Full = 'VENDOR_RISK',
  Centeraleyes_SAQ_Light = 'VENDOR_RISK_LIGHT',
  Centeraleyes_SAQ_Moderate = 'VENDOR_RISK_MODERATE',
  Centeraleyes_SAQ_High = 'VENDOR_RISK_HIGH',

  HECVAT_Lite = 'VENDOR_RISK_HECVAT_LITE',
  HECVAT_Onprem = 'VENDOR_RISK_HECVAT_ONPREM',
  HECVAT_Full = 'VENDOR_RISK_HECVAT',

  CSA_Full = 'VENDOR_RISK_CSA',
  ESG_Full = 'VENDOR_RISK_ESG',
  AI_GOVERNANCE_Full = 'VENDOR_RISK_AI_GOVERNANCE',
}

// FrameworkLevelFrontend Constants
export const FrameworkLevelFrontend = {
  // DBU: {
  //   'Vendor HECVAT Lite': ['Full'],
  // },
  INFORMATICA: {
    'Vendor SAQ': ['Tier 1', 'Tier 2', 'Tier 3'],
    OSAE: ['Full'],
  },
  HOWDEN: {
    'VENDOR RISK HOWDEN': ['Full', 'Cyber 360', 'Ransomware', 'Old'],
  },
  ITC: {
    'VENDOR RISK ITC': ['Full'],
  },
  CRB: {
    'VENDOR RISK CRB': ['Full'],
  },
  SSM_HEALTH: {
    'BA HIPAA': ['Full'],
    'VENDOR RISK SSM HEALTH': ['Full'],
  },
  ENBD: {
    'VENDOR RISK ENBD': ['Full'],
    'VENDOR RISK SAQ LITE': ['Full'],
    'VENDOR RISK SOC2': ['Full'],
  },
  ADNOC: {
    'VENDOR SAQ': ['V2', 'Full'],
    'VENDOR PRE SAQ': ['V2', 'Full'],
  },
  MOELIS: {
    'VENDOR RISK MOELIS': ['Full'],
  },
  REGULAR_INSTANCES: {
    'Centeraleyes SAQ': ['Full', 'High', 'Moderate', 'Light'],
  },
  COMMON: {
    HECVAT: ['Full', 'Onprem', 'Lite'],
    CSA: ['Full'],
    ESG: ['Full'],
    'AI GOVERNANCE': ['Full'],
  },
};

//* Levels that are used in Vendor Pipe
// TODO Please add LEVEL here if any new framework is added
// TODO Don't duplicate the levels
export const LEVELS = [
  'Tier 1',
  'Tier 2',
  'Tier 3',
  'Full',
  'Light',
  'Lite',
  'Moderate',
  'High',
  'Onprem',
  'Cyber 360',
  'Ransomware',
  'Old',
  'V2',
];
