/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_appsync_graphqlEndpoint: 'https://snz55iuvmvarrk5zifpjp4h4dy.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'jiraWebhookApiV1',
      endpoint: 'https://ybx22eb703.execute-api.us-east-1.amazonaws.com/dev',
      region: 'us-east-1',
    },
  ],
  aws_cognito_identity_pool_id: 'us-east-1:08540ce7-c67f-4b2f-bf00-7c05b168bcac',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_nxSrhML7M',
  aws_user_pools_web_client_id: '3l3j6k253fg4uarbarkr3govl8',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL', 'PHONE_NUMBER'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'ON',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_content_delivery_bucket: 'cygov-source-code-dev',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://d3371g1n5s5ydz.cloudfront.net',
  aws_user_files_s3_bucket: 'cygov-storage-dev',
  aws_user_files_s3_bucket_region: 'us-east-1',
};

export default awsmobile;
